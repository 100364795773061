<template>
  <div class="products">
    <div class="title">
      {{
        $store.getters.serviceType === 'service_provider'
          ? $t('enduser.services.servicesCategories')
          : $t('enduser.products.products')
      }}
    </div>

    <div class="categories-list">
      <div
        v-for="category in $store.getters.serviceCategories"
        :key="category.id"
        class="category"
        @click="
          $router.push({
            name:
              $store.getters.serviceType === 'retailer'
                ? 'RetailerCategoryDetails'
                : 'ServiceCategoryDetails',
            params: { id: category.id },
          })
        "
      >
        <div class="category-inner">
          <img
            v-if="category.image"
            :src="category.image"
            class="category-img"
          />
          <img
            v-else
            src="https://via.placeholder.com/240"
            class="category-img"
          />
          <div class="category-overlay"></div>
          <span class="category-name">{{ category.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../../store';
import { onMounted, watch } from '@vue/composition-api';

export default {
  setup() {
    onMounted(() => {
      store.dispatch('fetchServiceCategories');
    });

    watch(
      () => store.getters.serviceType,
      () => {
        store.dispatch('fetchServiceCategories');
      }
    );

    return {};
  },
};
</script>

<style lang="scss" scoped>
.categories-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem;

  .category {
    flex: 0 0 33%;
    padding: 0.5rem;
    cursor: pointer;
  }

  .category-inner {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    height: 140px;
  }

  .category-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .category-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(
      182deg,
      rgba(0, 0, 0, 0) 16.07%,
      rgba(0, 0, 0, 0.54) 98.28%
    );
  }

  .category-name {
    position: absolute;
    bottom: 0.5rem;
    left: 0.6rem;
    z-index: 15;
    font-size: 1rem;
    line-height: 1.3rem;
    color: white;
    overflow: auto;
  }
}
</style>
